import React, { useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menus from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { Context } from '../../Context/authContext';



import { Area_Aluno } from '../../Pages/Area_Aluno';


export const Menu = () => {

  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  const { handleLogout, authenticated } = useContext(Context);

  //console.log('O token é:' + token);




  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [isTransparent, setIsTransparent] = useState(true);


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsTransparent(false);
      } else {
        setIsTransparent(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <div>
      <nav className={`fixed top-0 mb-10 left-0 w-full flex items-center justify-between py-4 px-6 z-50 transition-all duration-300 ${isTransparent ? 'bg-emerald-700 shadow-lg' : ' bg-emerald-900 shadow-lg'}`}>
        <div className="flex items-center">
          <button onClick={toggleMenu} className="p-4 text-white "><FiMenu /> </button>
        </div>
        <div className="flex items-center">
          <h1 className="text-xl font-bold text-white"> INBRAVISA - Olá {localStorage.getItem('Nome')}</h1>
        </div>

        <div className="flex items-center">
          <Link to="/"><button type='button' onClick={handleLogout} className=' bg-red-500 hover:bg-red-800 p-2 rounded-lg border-white text-white'>Sair</button> </Link>



        </div>


      </nav>
      <div className={`fixed mt-20 inset-y-0 left-0 bg-emerald-900 w-64 text-white transition-all duration-300 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
        <ul className="py-4">
          <Link to={'/Area_Aluno'}><MenuItem onClick={handleClose}>Home</MenuItem></Link>
          <Link to={'/'}><MenuItem onClick={handleClose}>Comprar Protocolos</MenuItem></Link>
          {/*}
        <Link to={'/'}><MenuItem onClick={handleClose}>Certificado</MenuItem></Link>
        <Link to={'/'}><MenuItem onClick={handleClose}>Meus Dados</MenuItem></Link>
          {*/}
        </ul>

      </div>

    </div>
  );
}










/*
import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import Menus from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { Context } from '../../Context/authContext';



import{Area_Aluno} from '../../Pages/Area_Aluno';


export const Menu = () => {

  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  const { handleLogout, authenticated } = useContext(Context);

  console.log('O token é:' + token);




  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="bg-sky-700 p-5 flex justify-between text-white">
      <Button
        color="primary"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
      >
        <h1>   <FiMenu />   Menu</h1>
      </Button>
      <Menus
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Link to={'/'}><MenuItem onClick={handleClose}>Home</MenuItem></Link>
        <Link to={'/Area_Aluno'}><MenuItem onClick={handleClose}>Meus Protocolos</MenuItem></Link>
        <Link to={'/'}><MenuItem onClick={handleClose}>Certificado</MenuItem></Link>
        <Link to={'/'}><MenuItem onClick={handleClose}>Meus Dados</MenuItem></Link>
      </Menus>

      <div>
        <p className='ml-5 text-3xl'>INBRAVISA - Olá {localStorage.getItem('Nome')} </p>
      </div>

      <div>

      <Link to="/"><button type='button' onClick={handleLogout} className=' bg-red-500 hover:bg-red-800 p-2 rounded-lg border-white'>Sair</button> </Link>

      </div>

    </div>
  );
}

*/
