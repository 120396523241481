import React, { useEffect, useState } from "react";
import api from "../../config/configAPI";
import { Link, useParams } from "react-router-dom";




export const Assistir = () => {

    const { idaula, NomeAula, idProtocolo } = useParams();

    const [aulas, setAulas] = useState([]);


    const [contador] = useState({
        "idCadastro": localStorage.getItem("idUser"),
        "NomeCadastro": localStorage.getItem("Nome"),
        "idAula": idaula,
        "NomeAula": NomeAula
    });




    const Aula = async () => {
        await api.get("/VisualizarAula/" + idaula)
            .then((response) => {
                //console.log(response.data.aula);
                setAulas(response.data.aula);

            }).catch((err) => {
                if (err.response) {
                    console.log(err.response.data.mensagem);
                } else {
                    console.log("Erro: Tente Novamente");
                }
            });

    }


    const AulaAssistida = async () => {
        await api.post("/cadAssistidas", contador)
            .then((response) => {
                //console.log(response.data.mensagem);

            }).catch((err) => {
                if (err.response) {
                    console.log(err.response.data.mensagem);
                } else {
                    console.log("Erro: Tente Novamente");
                }
            });

    }

    useEffect(() => {
        AulaAssistida();
        Aula();
    }, [])


    const La = aulas.LinkAula //"https://www.youtube-nocookie.com/embed/YHf_0OSYg5s?si=fR-AUoWwGqSk8S3R"




    return (
        <div className=" bg-slate-500 w-auto h-auto align-middleml-20">
            <div className=" p-5 pl-10 pr-10 grid grid-cols-3 gap-4 justify-center items-center ">

                {/*}         
            <button className=" bg-slate-200 hover:bg-slate-300 hover:text-xl hover:text-sky-600 rounded-lg border border-1 border-sky-700 items-center text-center p-10 h-28" type="button">Baixar Material</button>
            <button className=" bg-slate-200 hover:bg-slate-300 hover:text-xl hover:text-sky-600 rounded-lg border border-1 border-sky-700 items-center text-center p-10 h-28" type="button">Avaliar Aula</button>
        { */}
            </div>

            <div className=" bg-slate-200 rounded-lg flex border border-1 border-sky-700 items-center text-center p-10 justify-center  m-10">

                <iframe className="border-1 border-sky-500" id="panda-6cb56808-38a8-4e7d-9402-1f8d369a7f2a" src={La} allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowfullscreen="true" width="720" height="360" fetchpriority="high"></iframe>

            </div>
            <Link to={"/Listar_Aulas/" + idProtocolo}>
                <div className="flex justify-center items-center">
                    <div className="bg-sky-700 hover:bg-sky-900 text-white hover:text-white rounded-lg border border-sky-700 text-center py-2 px-4 h-auto cursor-pointer">
                        Voltar
                    </div>
                </div>
            </Link>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}