import React, { useState, useEffect } from "react";
import { Menu } from '../../Components/Menu';
import { Link, useParams } from "react-router-dom";
import api from "../../config/configAPI";
import { Rodape } from "../../Components/Rodape";
import { FaShoppingCart, FaArrowLeft } from 'react-icons/fa';
import { IoMdArrowRoundBack } from "react-icons/io";






export const Protocolo = () => {


    const token = localStorage.getItem('token');

    const { id } = useParams();

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    })

    const [data, setData] = useState([
    ]);


    useEffect(() => {
        const getUser = async () => {

            await api.get("/Protocolo/" + id,)
                .then((response) => {
                    if (response.data.user) {
                        setData(response.data.user)
                    } else {
                        setStatus({
                            type: "error",
                            mensagem: "Erro. Usuário não encontrado"

                        })
                    }

                }).catch((err) => {
                    if (err.response) {
                        setStatus({
                            type: "error",
                            mensagem: err.response.data.mensagem
                        })
                    } else {
                        setStatus({
                            type: "error",
                            mensagem: "Erro. Tente mais tarde"
                        })
                    }
                })
        }
        getUser()
    }, [id])

    const mensagemAdd = {
        type: status.type,
        mensagem: status.mensagem
    }

    return (
        <div>
            <div className='mb-20'>
                <Menu />
                <br />
            </div>

            <div className="mt-10 bg-slate-200 xl:w-3/4 lg:w-2/3 md:w-2/3 mb-36 rounded-lg mx-auto p-5">
                <div className="flex flex-col md:flex-row">
                    <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-5">
                        <img className="w-full h-auto md:w-56 md:h-56 object-cover" src={data.LinkImagem} alt={data.Nome} />
                    </div>
                    <div className="flex-1">
                        <h1 className="text-3xl mb-5">
                            <strong>{data.Nome}</strong>
                        </h1>
                        <p>
                            <strong>Descrição: </strong>
                            <span className="whitespace-pre-line">{data.Descricao}</span>
                        </p>
                        <br />
                        <p>
                            <strong>Conteúdo: </strong>
                            <span className="whitespace-pre-line">{data.Conteudo}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between items-center">
                <div className="flex justify-start">
                    <Link className="flex items-center justify-center bg-sky-700 text-white p-4 hover:bg-sky-950 px-4 py-2 rounded-md mr-2" to={"/"}>
                        <IoMdArrowRoundBack />
                        <span>Voltar</span>
                    </Link>
                </div>
                <div className="flex flex-row space-x-4 items-center">
                    {data.Promocao !== 1 ? (
                        <div className="text-center mb-5 text-lg">
                            <p>R${data.Valor},00</p>
                        </div>
                    ) : (
                        <div className="text-center mb-5 flex flex-row space-x-4">
                            <p>PROMOÇÃO</p>
                            <p className="bg-red-500 line-through text-white text-lg">R${data.Valor},00</p>
                            <p className="bg-emerald-800 text-white p-1 text-lg">R${data.Valor_Desconto},00</p>
                        </div>
                    )}
                    {token ? (
                        <Link to={"/Checkout/" + data.id}>
                            <button className="flex items-center space-x-2 bg-emerald-600 hover:bg-emerald-800 text-white px-10 sm:px-5 md:px-5 py-2 rounded-md">
                                <FaShoppingCart size={20} />
                                <span>Comprar</span>
                            </button>
                        </Link>
                    ) : (
                        <Link to={"/Login/" + data.id}>
                            <button className="flex items-center space-x-2 bg-emerald-600 hover:bg-emerald-800 text-white px-10 py-2 rounded-md">
                                <FaShoppingCart size={20} />
                                <span>Comprar</span>
                            </button>
                        </Link>
                    )}
                </div>
            </div>
        </div>

    )
}

