import React, { useEffect, useState } from "react";
import api from "../../config/configAPI";
import { Link, useParams } from "react-router-dom";
import { Menu } from "../../Components/Menu_Aluno";
import { FaPlay } from "react-icons/fa";

import { FiltroAssistida } from "../../Filtros";
import { Assistir } from "../Assistir";




export const Listar_Aulas = () => {

    const { idProtocolo } = useParams();

    console.log('Id do Protocolo' + idProtocolo)

    const [server, setServer] = useState({
        type: '',
        mensagem: ''
    })

    const [server1, setServer1] = useState({
        type: '',
        mensagem: ''
    })

    const [server2, setServer2] = useState({
        type: '',
        mensagem: ''
    })

    const [data, setData] = useState([]);

    const [protocolo, setprotocolo] = useState([]);

    const [assistida, setAssistida] = useState([]);

    const [filtro, setFiltro] = useState([]);





    //Ver Nome do Protocolo
    const nomeProtocolo = async () => {
        await api.get("/Protocolo/" + idProtocolo)
            .then((response) => {
                //console.log(response);
                setprotocolo(response.data.user)
            }).catch((err) => {
                if (err.response) {
                    //console.log(err.response.data.mensagem);
                    setServer1({
                        type: 'Erro',
                        mensagem: err.response.data.mensagem
                    })
                } else {
                    //console.log("ERRO: Tente mais tarde!");
                    setServer1({
                        type: 'Erro',
                        mensagem: 'Conexão com o servidor perdida'
                    })
                }
            });
    }


    //Ver Lista dos Videos do Protocolo Técnico    
    const verProtocolo = async () => {
        await api.get("/ListVideos/" + idProtocolo)
            .then((response) => {
                //console.log(response);
                setData(response.data.users)
            }).catch((err) => {
                if (err.response) {
                    //console.log(err.response.data.mensagem);
                    setServer({
                        type: 'Erro',
                        mensagem: err.response.data.mensagem
                    })
                } else {
                    //console.log("ERRO: Tente mais tarde!");
                    setServer({
                        type: 'Erro',
                        mensagem: 'Conexão com o servidor perdida'
                    })
                }
            });
    }



    useEffect(() => {
        nomeProtocolo();
        verProtocolo();

    }, [])


    return (

        <div className=" bg-gray-200 h-auto">
            <Menu /><br />
            <br />
            <br />
            <div className="border pt-2 pb-10 m-5 border-sky-600">

                <div className="bg-green-800 text-white p-5 flex ml-20 mr-20 mt-5 justify-center border-b border-gray-700">

                    <p className="text-3xl  p-2 "> {protocolo.Nome} </p>

                </div>


                {data.map(user => (




                    <div className="bg-slate-100 p-5 flex ml-20 mr-20 mt-5 justify-between">

                        {user.NomeAula}

                        <br />

                        <div className="flex flex-row "> <FiltroAssistida num={user.id} /> <FaPlay className="ml-2 mr-2" />
                            <Link className="hover:text-sky-500" to={"/Assistir/" + user.id + "/" + user.NomeAula + "/" + user.idProtocolo}>
                                Assistir

                            </Link>






                        </div>

                    </div>

                ))}



            </div>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>

    )


}