import React from 'react';
import { PDFViewer, Document, Page, View, Text, StyleSheet, Image, Font } from '@react-pdf/renderer';
import logo from '../../Assets/logo.png';
import assrui from '../../Assets/assrui.png';
import EBGaramondFont from '../../Assets/EBGaramond-VariableFont_wght.ttf'; // Importando o arquivo da fonte

// Registrar a fonte Playwrite CO do Google Fonts
Font.register({
  family: 'EB Garamond',
  src: EBGaramondFont, // Caminho da fonte importada
});

export const Certificado = () => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row', // Define a direção da página como paisagem
      backgroundColor: '#FFF', // Cor de fundo branca
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      position: 'relative', // Necessário para posicionar elementos absolutamente dentro dela
    },
    title: {
      fontSize: 60,
      textAlign: 'center',
      marginBottom: 10,
      fontFamily: 'EB Garamond',
      textDecoration:'underline', 
    },
    txtemissao: {
      fontSize: 16,
      textAlign: 'left',
      position: 'absolute',
      bottom: 0,
      left: 15,
      marginBottom: 28,
      
    },
    dataemissao: {
      fontSize: 12,
      textAlign: 'left',
      position: 'absolute',
      bottom: 0,
      left: 10,
      marginBottom: 10,
      borderTopWidth: 1,
      padding: 2,
      
    },
    content: {
      fontSize: 22,
      textAlign: 'center',
      marginBottom: 5,
      marginTop: 5,
      fontFamily: 'EB Garamond',
      
    },
    cabecalho: {
      fontSize: 22,
      textAlign: 'center',
      marginBottom: 5,
      marginTop: 5,
      fontFamily: 'EB Garamond',
      
    },
    alunoNome: {
      fontSize: 36,
      textAlign: 'center',
      marginBottom: 5,
      marginTop: 5,
      fontFamily: 'EB Garamond',
      
    },
    alunoCpf: {
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 5,
      marginTop: 5,
      
    },
    cursoNome: {
      fontSize: 20,
      textAlign: 'center',
      marginBottom: 5,
      marginTop: 20,
      fontFamily: 'EB Garamond',
      
    },
    legislacao: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 5,
      fontFamily: 'EB Garamond',
      
    },
    logo: {
      width: 100, // Largura do logotipo
      height: 100, // Altura do logotipo
      position: 'absolute', // Define a posição absoluta
      right: 25, // Margem direita
      bottom: 20, // Margem inferior
    },
    logoFnd: {
      width: 500,
      height: 500,
      position: 'absolute',
      opacity: 0.04, // Define a opacidade da imagem (50%)
      top: 30, // Ajuste conforme necessário
      left: 180, // Ajuste conforme necessário
    },
    assrui: {
      width: 300, // Largura do logotipo
      height: 100, // Altura do logotipo
      position: 'absolute', // Define a posição absoluta
      bottom: 0,
      marginBottom: 10,      
      left: 240,
    },
    txtRui: {      
      fontSize: 14,
      textAlign: 'center',
      position: 'absolute',
      bottom: 0,
      marginBottom: 20,      
      paddingRight: 10,
      paddingLeft: 20,
      left: 290,
      borderTopWidth: 1,
      padding: 2,
      
    },
    txtCargo: {
      fontSize: 14,
      textAlign: 'center',
      position: 'absolute',
      bottom: 0,
      padding: 2,
      left: 280,
      
    },
    greenBorder: {
      borderColor: 'green',
      borderWidth: 3,
      flexGrow: 1,
    },
    yellowBorder: {
      borderColor: 'yellow',
      borderWidth: 3,
      flexGrow: 1,
      margin: 5,
    },
    blueBorder: {
      borderColor: 'blue',
      borderWidth: 3,
      flexGrow: 1,
      margin: 5,
    },
  });

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <View style={styles.blueBorder}>
            <View style={styles.yellowBorder}>
              <View style={styles.greenBorder}>
                <View style={styles.section}>
                  {/* <Image src={logoFnd} style={styles.logoFnd} />  Imagem de fundo */}
                  <Text style={styles.title}>CERTIFICADO</Text>
                  <Text style={styles.cabecalho}>O INBRAVISA - Instituto Brasileiro de Auditoria em Vigilância Sanitária, devidamente inscrito sob CNPJ: 50.832.414/0001-09</Text>
                  <Text style={styles.cabecalho}> Certifica que,</Text>
                  <Text style={styles.alunoNome}>Andressa Bianca Costa Dammenhain</Text>
                  <Text style={styles.alunoCpf}>CPF: XXX.XXX.XXX-XX</Text>
                  <Text style={styles.content}>concluiu o curso online do</Text>
                  <Text style={styles.cursoNome}> PROTOCOLO TÉCNICO SANITÁRIO PARA INSTALAÇÃO E FUNCIONAMENTO DE CENTROS DE INFUSÃO
                    </Text>
                  <Text style={styles.legislacao}>com duração de 2 horas, curso livre conforme Decreto Presidencial N° 5.154, de 23 de julho de 2004, Art. 1° e 3° e Portaria Nº 008, de 25/06/2002.</Text>
                  <Text style={styles.txtemissao}>12/06/2024</Text>
                  <Text style={styles.dataemissao}>Data de conclusão</Text>               
                  <Image src={assrui} style={styles.assrui} />
                  <Text style={styles.txtRui}>Rui de Andrade Dammenhain</Text>
                  <Text style={styles.txtCargo}>Diretor Presidente do INBRAVISA</Text>
                </View>
              </View>
            </View>
          </View>
          <Image src={logo} style={styles.logo} /> {/* Logotipo */}
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default Certificado;