import react from "@heroicons/react";
import { Menu } from "../../Components/Menu_Aluno";
import { CardCompras } from "../../Components/CardAdquiridos";
import { Rodape } from "../../Components/Rodape";




export const Area_Aluno = ()=>{

    localStorage.removeItem('Cadastrado');
    localStorage.removeItem('ValorCompra');
    localStorage.removeItem('Cupom');
    localStorage.removeItem('idCupom');
    localStorage.removeItem('Desconto');

    return(
        <div>
            <div className='mb-20'>
               <Menu /> 
               <br />
            </div>
            <div className=" bg-emerald-800 text-white p-5 flex ml-20 mr-20 mt-5 justify-center">
                Olá, {localStorage.getItem("Nome")}, essa é a sua área de Protocolos Técnicos adquiridos
            </div>
            <div className="p-5 flex ml-20 mr-20 mt-5 mb-36 justify-center">
                <CardCompras/>

            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            
        
            <div class="relative mt-10 mb-20">
            <Rodape/>
            </div>
        </div>

        
    )
}